import React from 'react';
import CommonDomainlessPageHeader from '../components/CommonDomainlessPageHeader';

type CustomDialogPageProps = {
    title: string;
    content: string;
};

const CustomDialogPage = ({ title, content }: CustomDialogPageProps) => {
    return (
        <>
            <CommonDomainlessPageHeader />
            <div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: '1024px' }}>
                    <h1>{title}</h1>
                    <p>{content}</p>
                </div>
            </div>
        </>
    );
};

export default CustomDialogPage;
