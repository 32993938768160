import React from 'react';
import { Heading2, Paragraph } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';

const AboutEnturSection = () => {
    return (
        <div style={{ backgroundColor: '#EBEBF1', display: 'flex', justifyContent: 'center', padding: '0 2rem' }}>
            <GridContainer style={{ maxWidth: '1024px', padding: '2rem 0' }}>
                <GridItem small={12} style={{ color: '#181C56' }}>
                    <Heading2 style={{ paddingTop: '1rem' }}>About Entur</Heading2>
                    <Paragraph style={{ paddingBottom: '1rem' }}>
                        Entur operates the national registry for all public transport in Norway, collecting data from 60
                        public transportation operators. The registry contains data regarding 21,000 daily departures
                        across 3,000 routes. This data is openly available.
                    </Paragraph>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default AboutEnturSection;
